import { createContext, useContext, useState } from "react";

export interface DateRange {
  startDate: string | null;
  endDate: string | null;
}

export interface DateRangeContextValue {
  dateRange: DateRange;
  setDateRange: (newRange: DateRange) => void;
}

export const DateRangeContext = createContext<DateRangeContextValue | null>(null);

export const useDateRangeContext = (): DateRangeContextValue => {
  const context = useContext(DateRangeContext);

  if (!context) {
    return {
      dateRange: { startDate: null, endDate: null }, // Default date range
      setDateRange: () => {
        console.warn("DateRangeContext is not provided. Using default setDateRange.");
      },
    };
  }

  return context;
};

export const DateRangeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  return (
    <DateRangeContext.Provider value={{ dateRange, setDateRange }}>
      {children}
    </DateRangeContext.Provider>
  );
};
