import { useState, useEffect, ChangeEvent } from "react";
import { useMutation } from "react-query";
import { Grid, useMediaQuery } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddIcon from "@mui/icons-material/Add";
import { InputField } from "../../components/inputs/TextField";
import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import {
  OnboardingAddIcon,
  OnboardingIcon,
  OnboardingImageContainer,
  OnboardingSelectedImage,
} from "../onBoarding/Onboarding.styles";
import { COLORS } from "../../constants/colors";
import DropdownField from "../../components/dropdownOnboarding/Dropdown";
import {
  addSalonImages,
  getCities,
  getCityParts,
} from "../../services/onboarding";
import { InputError } from "../../helpers/inputValidation/InputError";
import isFilledString from "../../helpers/inputValidation/validators/isFilledString";
import allValid from "../../helpers/inputValidation/validators/allValid";
import and from "../../helpers/inputValidation/validators/and";
import isPhoneNumber from "../../helpers/inputValidation/validators/isPhoneNumber";
import { convertDataUrlToBase64 } from "../onBoarding/Step3.helper";
import { PageContainer } from "../pageContainer/PageContainer";
import {
  OnboardingCenterContainer,
  ImagesContainer,
  StyledColumnGrid,
  ButtonsContainer,
  HeaderContainer,
} from "./Profile.styles";
import { IconButton } from "../../components/buttons/iconButton/IconButton";
import ImageWithDeleteButton from "../../components/imageWithDelete/imageWithDelete";
import { SecondaryButton } from "../../components/buttons/secondaryButton/SecondaryButton";
import { HeaderPage } from "../../constants/customStyles";
import DaysOffModal from "../../components/modal/DaysOffModal";
import {
  SalonImage,
  deleteSalonWebImages,
  editSalonWebInfo,
  useSalonWebImages,
  useSalonWebInfo,
  useSalonWorkingHours,
} from "../../services/salons";
import {
  Severity,
  useNotificationContext,
} from "../../context/NotificationContext";
import { City, CityPart } from "../onBoarding/Step1";
import { handleFileChange, handleImageUpload } from "../../helpers/imageUpload";
import LoaderWithLogo from "../../components/loader/loader";
import WorkingHoursModal from "../../components/modal/WorkingHoursModal";

export const SalonProfile = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { showNotification } = useNotificationContext();
  const [dataWorkingHours, setDataWorkingHours] = useState();
  const [isDaysOffModalOpen, setIsDaysOffModalOpen] = useState<boolean>(false);
  const [isWorkingHoursModalOpen, setIsWorkingHoursModalOpen] =
    useState<boolean>(false);

  const [cities, setCities] = useState<City[]>([]);
  const [cityParts, setCityParts] = useState<CityPart[]>([]);
  const [cityId, setCityId] = useState<number>();
  const [cityPartId, setCityPartId] = useState<number>();
  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [mobilePhone, setMobilePhone] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [phoneError, setPhoneError] = useState<InputError | null>(null);
  const [nameError, setNameError] = useState<InputError | null>(null);
  const [addressError, setAddressError] = useState<InputError | null>(null);

  const [coverImage, setCoverImage] = useState<string>("");
  const [newCoverImage, setNewCoverImage] = useState<string>("");
  const [images, setImages] = useState<SalonImage[]>([]);
  const [newImages, setNewImages] = useState<string[]>([]);
  const [deletedImageIds, setDeletedImageIds] = useState<number[]>([]);

  const { data, refetch, isSuccess, isLoading } = useSalonWebInfo();
  const {
    data: dataHours,
    refetch: refetchHours,
    isSuccess: isSuccessHours,
    isLoading: isLoadingHours,
  } = useSalonWorkingHours();

  const {
    data: dataWebImages,
    refetch: refetchWebImages,
    isSuccess: isSuccessWebImages,
    isLoading: isLoadingWebImages,
  } = useSalonWebImages();

  const { mutate: editSalonWebInfoMutation } = useMutation(editSalonWebInfo, {
    onSuccess: () => {
      showNotification("Profil salona uspesno izmenjen", Severity.Success);
      refetch();
    },
    onError: (error) => {
      showNotification("Greška prilikom izmene profila salona", Severity.Error);
    },
  });

  const { mutate: addSalonImagesMutation } = useMutation(addSalonImages, {
    onSuccess: () => {
      refetchWebImages();
    },
    onError: (error) => {
      showNotification("Greška prilikom dodavanja slika", Severity.Error);
    },
  });

  const { mutate: deleteSalonImagesMutation } = useMutation(
    deleteSalonWebImages,
    {
      onSuccess: () => {
        refetchWebImages();
      },
      onError: (error) => {
        showNotification("Greška prilikom brisanja slika", Severity.Error);
      },
    }
  );

  const validatePhoneNumber = (): boolean => {
    const validated = {
      mobilePhone: and(isFilledString(mobilePhone, InputError.Empty), () =>
        isPhoneNumber(mobilePhone, InputError.InvalidPhoneNumber)
      ),
    };
    if (!allValid(validated)) {
      setPhoneError(
        validated.mobilePhone.isValid ? null : validated.mobilePhone.error
      );

      return false;
    }
    setPhoneError(null);
    return true;
  };

  const validateFormFields = (): boolean => {
    const validated = {
      mobilePhone: and(isFilledString(mobilePhone, InputError.Empty), () =>
        isPhoneNumber(mobilePhone, InputError.InvalidPhoneNumber)
      ),
      name: isFilledString(name, InputError.Empty),
      address: isFilledString(address, InputError.Empty),
    };
    if (!allValid(validated)) {
      setPhoneError(
        validated.mobilePhone.isValid ? null : validated.mobilePhone.error
      );
      setNameError(validated.name.isValid ? null : validated.name.error);
      setAddressError(
        validated.address.isValid ? null : validated.address.error
      );

      return false;
    }
    setPhoneError(null);
    setNameError(null);
    setAddressError(null);

    return true;
  };

  const setValuesToDataValues = () => {
    if (data?.result) {
      const { mobilePhone, name, address, description, coverImage } =
        data.result;
      setName(name);
      setMobilePhone(mobilePhone);
      setAddress(address);
      setDescription(description);
      setCoverImage(coverImage);
      setCityId(data.result.cityDto.key);
      setCityPartId(data.result.cityPartDto.key);
    }
  };

  useEffect(() => {
    setValuesToDataValues();
  }, [isSuccess]);

  useEffect(() => {
    dataWebImages?.result &&
      dataWebImages.result.map((file) => {
        setImages((prevImages) => [...prevImages, file]);
      });
  }, [isSuccessWebImages]);

  useEffect(() => {
    isSuccessHours && setDataWorkingHours(dataHours.result);
  }, [isSuccessHours]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleFileChange(event, setNewImages);
  };

  const handleDeleteImage = (index: number) => {
    const deletedImageId = images[index].id;
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    setDeletedImageIds((prevIds) => [...prevIds, deletedImageId]);
  };

  const handleDeleteNewimages = (index: number) => {
    const updatedNewImages = newImages.filter((_, i) => i !== index);
    setNewImages(updatedNewImages);
  };

  const handleEditSalonWebInfo = () => {
    const data = {
      name,
      address,
      mobilePhone,
      description,
      cityPartId,
      cityId,
    };
    if (validateFormFields() && validatePhoneNumber()) {
      const dataForEdit = newCoverImage
        ? {
            ...data,
            coverImage: coverImage.substring("data:image/png;base64,".length),
          }
        : data;
      editSalonWebInfoMutation(dataForEdit);
      newImages?.length && addSalonImagesMutation({ images: newImages });
      deletedImageIds?.length && deleteSalonImagesMutation(deletedImageIds);
    }
  };

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await getCities();
        setCities(response.result);
      } catch (error) {
        showNotification("Greška prilikom ucitavanja gradova", Severity.Error);
      }
    };
    fetchCities();
  }, []);

  useEffect(() => {
    const fetchCityParts = async () => {
      try {
        const selectedCity = cities.find((city) => city.key === cityId);
        if (selectedCity) {
          const cityPartsResponse = await getCityParts(selectedCity.key);
          const parts = cityPartsResponse.result;
          setCityParts(parts.cityParts || []);
        } else {
          console.error("Selected city not found.");
        }
      } catch (error) {
        showNotification("Greška prilikom ucitavanja gradova", Severity.Error);
      }
    };

    fetchCityParts();
  }, [cityId, cityPartId]);

  const handleAddIconClick = (event) => {
    event.stopPropagation();
    handleImageUpload(handleCoverImageChange, false);
  };

  const handleCoverImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result?.toString() || "";
        setCoverImage(
          `data:image/png;base64, ${convertDataUrlToBase64(imageUrl)}`
        );
        setNewCoverImage(imageUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDaysOffModal = (open: boolean) => {
    setIsDaysOffModalOpen(open);
  };

  const handleWorkingHoursModal = (open: boolean) => {
    setIsWorkingHoursModalOpen(open);
  };

  return (
    <PageContainer>
      {isLoading || isLoadingWebImages || isLoadingHours ? (
        <LoaderWithLogo></LoaderWithLogo>
      ) : (
        <>
          <HeaderContainer>
            <HeaderPage>Profil salona</HeaderPage>
            <ButtonsContainer>
              <PrimaryButton
                width={isMobile ? "50%" : 155}
                text="Dodaj neradne dane"
                onClick={() => handleDaysOffModal(true)}
                fontSize={isMobile ? "0.75rem" : "1rem"}
              />
              <PrimaryButton
                width={isMobile ? "50%" : 155}
                text="Radno vreme"
                onClick={() => handleWorkingHoursModal(true)}
                fontSize={isMobile ? "0.75rem" : "1rem"}
              />
            </ButtonsContainer>
          </HeaderContainer>
          <OnboardingCenterContainer>
            <OnboardingImageContainer
              onClick={() => handleImageUpload(handleCoverImageChange, false)}
            >
              <OnboardingIcon>
                <CameraAltIcon
                  style={{
                    fontSize: isMobile ? "1.875rem" : "2.1875rem",
                    color: COLORS.mediumGray,
                  }}
                />
              </OnboardingIcon>
              {coverImage && (
                <OnboardingSelectedImage
                  src={coverImage}
                  alt="Izabrana slika"
                />
              )}
              <OnboardingAddIcon onClick={handleAddIconClick}>
                <AddIcon
                  style={{
                    color: COLORS.white,
                    fontSize: isMobile ? "1rem" : "1.5rem",
                  }}
                />
              </OnboardingAddIcon>
            </OnboardingImageContainer>
            <Grid container spacing={2} style={{ marginTop: "0.3125rem" }}>
              <StyledColumnGrid item xs={isMobile ? 12 : 6}>
                <InputField
                  onChange={(event) => setName(event.target.value)}
                  label={Boolean(nameError) ? nameError : "Ime salona"}
                  placeholder="Ime salona"
                  value={name}
                  error={Boolean(nameError)}
                  onFocus={() => setNameError(null)}
                />
                <InputField
                  label={Boolean(addressError) ? addressError : "Adresa"}
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                  placeholder="Adresa"
                  error={Boolean(addressError)}
                  onFocus={() => setAddressError(null)}
                />
                {/* <DropdownField
                  label={"Grad"}
                  options={cities.map((city) => ({
                    value: city.key,
                    label: city.value,
                  }))}
                  value={cityId || ""}
                  onChange={(event) => setCityId(event.target.value)}
                />
                <DropdownField
                  label={"Deo grada"}
                  options={
                    cityParts &&
                    Array.isArray(cityParts) &&
                    cityParts.length > 0
                      ? cityParts.map((part) => ({
                          value: part.key,
                          label: part.value,
                        }))
                      : [{ value: "", label: "Nema dostupnih delova grada" }]
                  }
                  value={cityPartId || ""}
                  onChange={(event) => setCityPartId(event.target.value)}
                /> */}
                <InputField
                  label={Boolean(phoneError) ? phoneError : "Telefon"}
                  onChange={(event) => setMobilePhone(event.target.value)}
                  placeholder="Telefon"
                  value={mobilePhone}
                  error={Boolean(phoneError)}
                  onBlur={() => validatePhoneNumber()}
                  onFocus={() => setPhoneError(null)}
                />
              </StyledColumnGrid>
              <StyledColumnGrid item xs={isMobile ? 12 : 6}>
                <InputField
                  label="Opis"
                  multiline
                  rows={6}
                  placeholder="Opis"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Foto galerija</span>
                  <div>
                    <IconButton
                      onClick={() => handleImageUpload(handleChange, true)}
                    >
                      <AddIcon
                        style={{
                          color: COLORS.white,
                          fontSize: isMobile ? "1rem" : "1.5rem",
                        }}
                      />
                    </IconButton>
                  </div>
                </div>

                <ImagesContainer>
                  {images.map((imageUrl, index) => (
                    <ImageWithDeleteButton
                      key={index}
                      imageUrl={imageUrl.salonImagesUrl}
                      onDelete={() => handleDeleteImage(index)}
                    />
                  ))}
                  {newImages.map((imageUrl, index) => (
                    <ImageWithDeleteButton
                      key={index}
                      imageUrl={`data:image/png;base64, ${imageUrl}`}
                      onDelete={() => handleDeleteNewimages(index)}
                    />
                  ))}
                </ImagesContainer>
              </StyledColumnGrid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={isMobile ? 12 : 6}>
                <PrimaryButton
                  text={"Sacuvaj"}
                  width={"100%"}
                  onClick={() => handleEditSalonWebInfo()}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <SecondaryButton
                  text={"Ponisti"}
                  width={"100%"}
                  onClick={() => {
                    setValuesToDataValues();
                    setNewImages([]);
                  }}
                />
              </Grid>
            </Grid>
          </OnboardingCenterContainer>
          {isDaysOffModalOpen && (
            <DaysOffModal
              isModalOpen={isDaysOffModalOpen}
              handleClose={() => handleDaysOffModal(false)}
              title="Neradni dani salona"
            />
          )}
          {isWorkingHoursModalOpen && (
            <WorkingHoursModal
              data={dataWorkingHours}
              isModalOpen={isWorkingHoursModalOpen}
              handleClose={() => handleWorkingHoursModal(false)}
            />
          )}
        </>
      )}
    </PageContainer>
  );
};
