import { Box, styled } from "@mui/material";
import { COLORS } from "../../constants/colors";

export const StyledUniversalModal = styled(Box)(({ theme, width, bottom }) => ({
  width: `${width ? width : "fit-content"}`,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: COLORS.white,
  border: "none",
  borderRadius: "0.5625rem",
  padding: "3.125rem",
  boxShadow: `0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);`,

  "&::-webkit-scrollbar": {
    width: "0.4rem",
  },

  [theme.breakpoints.down("md")]: {
    padding: "2.5rem",
  },

  [theme.breakpoints.down("sm")]: {
    bottom: bottom ? `${bottom}` : "0",
    top: "auto", 
    transform: `translate(-50%, ${bottom ? "-50%" : "0"})`,
    overflow: "hidden",
    width: "100vw",
   // height: "100vh",
    justifyContent: "flex-start",
    padding: "1.875rem",
  },
}));
