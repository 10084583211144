const PREFIX = "/api/";
const PREFIX_SALONS_AUTH = `${PREFIX}salons-auth`;
const PREFIX_WORKER_AUTH = `${PREFIX}workers-auth`;
const PREFIX_SALONS = `${PREFIX}salons`;
export const SALONS_WORKING_HOURS = `${PREFIX_SALONS}/workingHours`;

export const PREFIX_SALONS_WEB = `${PREFIX}salons-web`;
const PREFIX_LOCATIONS = `${PREFIX}locations`;
const PREFIX_DAYS_OFF = `${PREFIX}daysOff`;

export const GENERAL_INFO = `${PREFIX_SALONS}/generalInfo`;
export const SEND_EMAIL = `${PREFIX_SALONS_AUTH}/sendConfirmationEmail`;
export const REGISTER_PASSWORD = `${PREFIX_SALONS_AUTH}/register`;
export const LOGIN = `${PREFIX_SALONS_AUTH}/login`;
export const REGISTER_WORKER = `${PREFIX_WORKER_AUTH}/register`;
export const IMAGES = `${PREFIX_SALONS}/images`;

export const PREFIX_SERVICES = `${PREFIX}services`;
const PREFIX_RESERVATIONS = `${PREFIX}reservations`;
export const FETCH_APPOINTMENTS = `${PREFIX_RESERVATIONS}/salonAppointments`;
export const MANUAL_RESERVATION = `${PREFIX_RESERVATIONS}/manualReservation`;
export const MANUAL_RESERVATION_EDIT = (id?: number) =>
 `${PREFIX_RESERVATIONS}/manualReservation/${id}`;
export const CANCEL_RESERVATION = `${PREFIX_RESERVATIONS}/cancelBySalon`;
export const FREE_TIME_SLOTS = `${PREFIX_RESERVATIONS}/availableTimeSlotsForSalon`;

const PREFIX_NOTIFICATIONS = `${PREFIX}notifications`;
export const NOTIFY_STATUS = `${PREFIX_NOTIFICATIONS}/notifyAboutStatus`;
export const SERVICE_GROUP = `${PREFIX_SERVICES}/serviceGroup`;
export const SERVICE_GROUPS = `${PREFIX_SERVICES}/serviceGroups`;
export const SERVICES_BY_GROUP = `${PREFIX_SERVICES}/byServiceGroup`;

export const CITIES = `${PREFIX_LOCATIONS}/cities`;
export const CITY_PARTS = (cityId: number) =>
  `${PREFIX_LOCATIONS}/cityParts/${cityId}`;

export const WORKERS = `${PREFIX}workers`;

export const DAYS_OFF_SALON = `${PREFIX_DAYS_OFF}/salon`;
export const DAYS_OFF_WORKER = `${PREFIX_DAYS_OFF}/worker`;
export const DAYS_OFF_ALL = `${PREFIX_DAYS_OFF}/allWorkers`;

export const SALONS_WEB_INFO = `${PREFIX_SALONS_WEB}/info`;
export const SALONS_WEB_IMAGES = `${PREFIX_SALONS_WEB}/images`;
export const SALONS_WEB_HOURS = `${PREFIX_SALONS_WEB}/workingHours`;

export const PREFIX_ANALYTICS = `${PREFIX}analytics`;
export const ANALYTICS_APPOINTMENTS_INFO = `${PREFIX_ANALYTICS}/appointmentsInfo`;
export const ANALYTICS_SERVICES_INFO = `${PREFIX_ANALYTICS}/servicesInfo`;
