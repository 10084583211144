import { useQuery } from "react-query";
import apiClient from "../api/api";
import { FREE_TIME_SLOTS } from "../routes/routes";

export const fetchSalonFreeSlots = async ({
  date,
  serviceIds,
  reservationId,
}: {
  date: string;
  serviceIds: [];
  reservationId?: number;
}) => {
  const { data } = await apiClient.post(FREE_TIME_SLOTS, { date, serviceIds, reservationId });

  return data;
};

export const useSalonFreeSlots = (
  date: string,
  serviceIds: any,
  reservationId?: number
) => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    [`salon-free-time-slots${date}`],
    () => fetchSalonFreeSlots({ date, serviceIds, reservationId }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: serviceIds !== null,
    }
  );

  return {
    data,
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};
